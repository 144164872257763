// Global imports
import { Link, Utils } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'react-navi';
import { useAxios } from '../../utils/hooks';

// Local imports
import Logo from '../Logo';
import SkipLink from '../SkipLink';
import { cancelAmendedForm } from '../../utils/cancelAmendedForm';

// Styles
import './Header.scss';

const DEFAULT_CLASS = 'hods-header';
const Header = () => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const axiosInstance = useAxios();

  const onLogoClick = async (e) => {
    e.preventDefault();
    cancelAmendedForm(axiosInstance);
    await navigation.navigate('/');
  };
  const onLogoutClick = async (e) => {
    e.preventDefault();
    cancelAmendedForm(axiosInstance);
    await navigation.navigate('/logout');
  };

  const classes = Utils.classBuilder(DEFAULT_CLASS, []);
  return (
    <header className={classes()} role="banner" data-module="govuk-header">
      <SkipLink />
      <div className={`${classes('container')} hods-width-container`}>
        <div className={classes('main')}>
          <div className={classes('logo')}>
            <Link id="home" href="/" onClick={onLogoClick} tabIndex="-1">
              <Logo />
            </Link>
          </div>
          <div className={classes('title')}>
            <Link
              href="/"
              onClick={onLogoClick}
              classBlock={classes('title__link')}
              classModifiers="service-name"
            >
              {t('header.service-name')}
            </Link>
          </div>
          <div className={classes('links')}>
            <Link id="profile" href="/forms/edit-your-profile">
              {t('header.your-profile')}
            </Link>
            <Link id="logout" href="/logout" onClick={onLogoutClick}>
              {t('header.sign-out')}
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
